import React from 'react';
import { organisationsContent } from '../../data/hrmsContent';
import organizationsImage from "../../images/organizationsImage.svg";

export const Organizations = () => {
  return (
      <section className=''>
          <div className='flex flex-col items-center py-12 px-4 lg:px-0'>
        <h1 className='font-[700] text-[25px] xl:text-[33px] tracking-tighter lg:w-[640px] lg:text-center'>{organisationsContent.title.normal}<span className='text-[#0361FE]'>{organisationsContent.title.coloured}</span>{organisationsContent.title.normalContinuation}</h1>
        <div className='flex flex-wrap items-center justify-center lg:space-x-16 mt-8'>
          {
            organisationsContent.companies.map((company, index) => {
              return (
                <img src={company.image} alt={company.name} key={index} title={company.name} className="mr-6 mt-4 md:mt-0 lg:mr-0"/>
              )
            })
          }
        </div>  
          </div>
          
    </section>
  )
}
