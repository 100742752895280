import React from 'react';
import { bannerContent } from '../../data/hrmsContent';
import { useModal } from '../ContactForm';
import "./hrms.css";


export const HRMSBanner = () => {
  const {modalAction}=useModal()
  return (
    <section className='background_image lg:h-[550px] 2xl:h-[680px] w-full p-5 lg:p-16'>
          <div className='purple_to_gradient font-[700] text-[32px] lg:text-[40px] xl:text-[62px] tracking-tighter'>
          {/* <div className='text-[#0361FE] font-[700] text-[32px] lg:text-[40px] xl:text-[62px] tracking-tighter'> */}
              <h1 className=''>{bannerContent.title.first}</h1>
              <h1 className=''>{bannerContent.title.second}</h1>
          </div>
          <p className='md:w-3/5 text-[16px] lg:text-[18px] font-[400] leading-[30px] lg:leading-[40px] py-4'>{bannerContent.paraLine}</p>
              <button className='bg-[#0361FE] text-white py-[11px] px-[25px] mt-3 rounded-[48px]' onClick={modalAction}>{bannerContent.button}</button>

          </section>
  )
}
